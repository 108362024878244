// Generated by Framer (d0e15c0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ZDdGpj3JM", "k8Ugzfgjy"];

const serializationHash = "framer-2SrU7"

const variantClassNames = {k8Ugzfgjy: "framer-v-95abl6", ZDdGpj3JM: "framer-v-d31k18"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Burger: "ZDdGpj3JM", X: "k8Ugzfgjy"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, iWF8EWIN1: color ?? props.iWF8EWIN1 ?? "rgb(136, 136, 136)", TBgHLr7MW: tap ?? props.TBgHLr7MW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ZDdGpj3JM"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TBgHLr7MW, iWF8EWIN1, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ZDdGpj3JM", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapsqnio = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("k8Ugzfgjy")
})

const onTapvbhp4u = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("ZDdGpj3JM")
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "k8Ugzfgjy") return false
return true
}

const isDisplayed1 = () => {
if (baseVariant === "k8Ugzfgjy") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-2SrU7", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-d31k18", className)} data-framer-name={"Burger"} data-highlight layoutDependency={layoutDependency} layoutId={"ZDdGpj3JM"} onTap={onTapsqnio} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({k8Ugzfgjy: {"data-framer-name": "X", onTap: onTapvbhp4u}}, baseVariant, gestureVariant)}><motion.div className={"framer-rzmnyv"} layoutDependency={layoutDependency} layoutId={"vZgJJ08s8"}><motion.div className={"framer-ehug69"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"XT8svCEUS"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} variants={{k8Ugzfgjy: {rotate: -45}}}/><motion.div className={"framer-1ro4qqy"} data-framer-name={"Mid"} layoutDependency={layoutDependency} layoutId={"bZkEumHd6"} style={{backgroundColor: iWF8EWIN1, opacity: 1}} variants={{k8Ugzfgjy: {opacity: 0}}}/>{isDisplayed() && (<motion.div className={"framer-aye8hn"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"qmbvkoJy2"} style={{backgroundColor: iWF8EWIN1}}/>)}{isDisplayed1() && (<motion.div className={"framer-130a011"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"gRKXu4xIX"} style={{backgroundColor: iWF8EWIN1, rotate: 45}}/>)}</motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-2SrU7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2SrU7 .framer-p68ttn { display: block; }", ".framer-2SrU7 .framer-d31k18 { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-2SrU7 .framer-rzmnyv { flex: none; height: 18px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 18px / 2); width: 24px; }", ".framer-2SrU7 .framer-ehug69 { bottom: 0px; flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; }", ".framer-2SrU7 .framer-1ro4qqy, .framer-2SrU7 .framer-130a011 { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: calc(50.00000000000002% - 2px / 2); }", ".framer-2SrU7 .framer-aye8hn { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-2SrU7.framer-v-95abl6 .framer-ehug69 { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-2SrU7.framer-v-95abl6 .framer-1ro4qqy { left: unset; width: 2px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"k8Ugzfgjy":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","iWF8EWIN1":"color"}
 * @framerImmutableVariables true
 */
const FrameruEC2hZAIc: React.ComponentType<Props> = withCSS(Component, css, "framer-2SrU7") as typeof Component;
export default FrameruEC2hZAIc;

FrameruEC2hZAIc.displayName = "Elements/Menu Icon";

FrameruEC2hZAIc.defaultProps = {height: 32, width: 32};

addPropertyControls(FrameruEC2hZAIc, {variant: {options: ["ZDdGpj3JM", "k8Ugzfgjy"], optionTitles: ["Burger", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}, iWF8EWIN1: {defaultValue: "rgb(136, 136, 136)", title: "Color", type: ControlType.Color}} as any)

addFonts(FrameruEC2hZAIc, [])